type ENVIRONMENT = 'dev' | 'preprod' | 'prod';

const detectEnvironment = () => {
  const injectedEnvironment =
    'prod'.toLocaleLowerCase() as unknown as ENVIRONMENT;

  if (injectedEnvironment.includes('@')) {
    return 'dev';
  }

  return injectedEnvironment;
};

const environment = detectEnvironment();

const createProdConfig = () => ({
  sso: {
    url: 'https://sso.dpdgroup.com/auth',
    realm: 'DPD',
    clientId: 'PROD-SP-FE',
  },
  baseUrl: '/api',
  environment: 'prod' as ENVIRONMENT,
  version: '1.34.0',
});

const createPreprodConfig = (): Config => ({
  ...createProdConfig(),
  environment: 'preprod' as const,
});

const createDevConfig = (): Config => ({
  sso: {
    url: process.env.REACT_APP_SSO_URL || 'http://localhost:8080/auth',
    realm: process.env.REACT_APP_SSO_REALM || 'DPD',
    clientId: process.env.REACT_APP_SSO_CLIENT_ID || 'SUPPORT-PANEL-FE',
  },
  baseUrl: process.env.REACT_APP_API_URL || '',
  version: process.env.REACT_APP_VERSION || '-development',
  environment: 'dev' as const,
});

type Config = ReturnType<typeof createProdConfig>;

const createConfiguration = (): Config => {
  switch (environment) {
    case 'preprod': {
      return createPreprodConfig();
    }
    case 'dev': {
      return createDevConfig();
    }
    default: {
      return createProdConfig();
    }
  }
};

export const configuration = createConfiguration();
